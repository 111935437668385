<template>
  <page-layout title="Payment">
    <div>
      <payment :wallet="user.wallet" />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "../components/base/pageLayout.vue";

import Payment from "../components/Payment.vue";
import { mapState } from "vuex";
export default {
  components: { Payment, PageLayout },
  computed: {
    ...mapState(["user"]),
  },
};
</script>