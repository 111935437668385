<template>
  <div>
    <div class="mx-4 my-2" v-if="tutor_previewed">
      <div class="bg-beige rounded-2xl w-full py-2 px-4 my-6">
        <p
          class="text-xl font-bold text-orange pt-3 pb-6 float-right"
          :key="updateAmount"
        >
          <span v-if="discounted_amount != null">{{ discounted_amount }}</span>
          <span
            :class="{
              'line-through pl-1 text-gray-300': discounted_amount != null,
            }"
            >{{ this.tutor_previewed.rate * 1.075 }}</span
          >
          EGP
        </p>
        <p class="text-lg font-bold pt-3">Amount</p>
      </div>
      <div class="p-4">
        <p class="w-1/2 text-lg font-bold pt-3">Add promocode</p>
        <div class="w-full flex items-center space-x-2">
          <form-input
            v-model:value="promocode"
            placeholder="type in your discount code"
            :label="``"
            :disabled="promoAdded"
          />
          <Button text="add" type="primary" small @click="getCode" />
        </div>
      </div>
      <div class="bg-beige rounded-2xl w-full py-2 px-4">
        <p class="w-1/2 text-lg font-bold pt-3 pb-6">Pay with</p>

        <!-- <payment-method
          isWallet
          :walletAmount="wallet.amount"
          @click="selected = 'wallet'"
          :selected="selected === 'wallet'"
        /> -->
        <div :class="{ 'opacity-25': discounted_amount === 0 }">
          <payment-method
            L4D=""
            @click="
              discounted_amount == null || discounted_amount > 0
                ? (selected = 'card')
                : ''
            "
            :selected="
              discounted_amount == null || discounted_amount > 0
                ? selected === 'card'
                : true
            "
          />
          <payment-method
            fawry
            @click="
              discounted_amount == null || discounted_amount > 0
                ? (selected = 'fawry')
                : ''
            "
            :selected="
              discounted_amount == null || discounted_amount > 0
                ? selected === 'fawry'
                : false
            "
          />
        </div>
        <div class="relative">
          <div class="mt-16 mb-5">
            <div class="flex items-center justify-around">
              <button
                class="
                  bg-orange-tint
                  w-32
                  py-3
                  rounded-full
                  text-center text-orange
                  font-bold
                  text-lg
                  capitalize
                "
                @click="this.$router.push(`/${user.username}/dashboard`)"
              >
                pay later
              </button>
              <button
                class="
                  bg-orange
                  w-32
                  py-3
                  rounded-full
                  text-center text-white
                  font-bold
                  text-lg
                  capitalize
                "
                @click="pay"
              >
                pay now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FormInput from "./formInput.vue";
import Button from "./button.vue";
import PaymentMethod from "./paymentMethod.vue";
import { useRouter, useRoute } from "vue-router";
import GetPromo from "../GetPromo";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { username } = route.params;
    return { username, router };
  },
  computed: {
    ...mapState(["tutor_previewed", "session_confirmation_preview", "user"]),
  },
  components: {
    PaymentMethod,
    FormInput,
    Button,
  },
  created() {
    if (!this.tutor_previewed) this.get_tutor(this.username);
  },
  data() {
    return {
      showModal: false,
      selected: "fawry",
      add: false,
      componentKey: 0,
      discounted_amount: null,
      promocode: "",
      updateAmount: 0,
      promoAdded: false,
    };
  },
  props: [
    "wallet",
    // 'last_4'
  ],
  methods: {
    ...mapActions(["payWithWallet", "payWithPaymob", "get_tutor"]),
    ...mapMutations(["fireToast"]),
    async getCode() {
      const promo = await GetPromo.get(this.promocode, this.user.email, this.tutor_previewed.user.email);
      if (!!promo && promo.eligible) {
        this.discounted_amount = GetPromo.applyDiscount(
          promo.promo,
          this.tutor_previewed.rate * 1.075
        );
        this.promoAdded = true;
        this.fireToast({
          text: `Promocode used!`,
          type: "success",
        });
      } else {
        this.discounted_amount = null;
        this.promoAdded = false;
        this.fireToast({
          text: `Promocode invalid! ${promo.message}`,
          type: "error",
        });
      }
      this.updateAmount++;
    },

    async pay() {
      let payment_amount;
      if (this.discounted_amount === null)
        payment_amount = this.tutor_previewed.rate * 1.075;
      else {
        payment_amount = this.discounted_amount;
        await GetPromo.use(this.promocode, this.user.email, this.tutor_previewed.user.email);
        if (this.discounted_amount === 0) this.selected = "card"
      }

      const session =
        this.session_confirmation_preview?.id ||
        window.localStorage.getItem("session_previewed_id");
      if (this.selected === "wallet") {
        await this.payWithWallet({
          receiver: this.tutor_previewed._id,
          amount: payment_amount,
          session,
        });
        this.$router.push(`/sessions/${session}`);
      } else if (this.selected === "fawry") this.$router.push(`/pay/fawry`);
      else
        this.payWithPaymob({
          session_id: session,
          payment_amount,
          promocode: this.promocode,
        });
    },
  },
};
</script>

