<template>
  <div>
    <div
      class="relative h-20 flex justify-between my-1 bg-white py-6 rounded"
      :class="{
        ring: selected,
        'ring-orange': selected,
        'ring-opacity-50': selected,
      }"
    >
      <div class="w-24 mx-4">
        <img
          v-if="fawry"
          :src="require('@/assets/fawry.svg')"
          alt="pay with fawry"
          class="w-full block"
        />
        <div
          v-else
          class="absolute w-16 h-10 rounded z-20 flex items-center"
          :class="{ 'bg-orange': isWallet, ' bg-shark': !isWallet }"
        >
          <p class="font-bold uppercase text-white mx-auto">
            {{ isWallet ? "wallet" : fawry ? "Fawry" : "card" }}
          </p>
        </div>
        <div
          v-if="!fawry"
          class="
            absolute
            w-16
            h-10
            transform
            -rotate-12
            bg-gray-300
            rounded
            z-10
          "
          :class="{
            'bg-orange-tint': isWallet,
            ring: selected,
            'ring-orange-tint': selected,
          }"
        ></div>
      </div>
      <p class="mx-6 font-extralight my-1">
        {{ isWallet ? "balance: " : fawry ? "" : "**** "
        }}<span class="font-bold text-orange">{{
          isWallet ? walletAmount + " EGP" : L4D
        }}</span>
      </p>
      <div
        class="my-1 mr-4 w-6 h-6 rounded-full bg-white ring ring-orange-tint"
        :class="{ 'bg-orange': selected }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isWallet: Boolean,
    L4D: String,
    selected: Boolean,
    walletAmount: Number,
    fawry: Boolean,
  },
};
</script>